import React, {useEffect} from "react";
import { navigate } from "gatsby";

import CategoryTemplatePage from "../../templates/category/Category";

import spolecenskeSatyCollections from "./../../queries/spolecenskeSatyCollections";

const SpolecenskeSatyPage = () => {
  useEffect(() => {
    navigate("/spolecenske-saty/spolecenske-20");
  }, []);

  const spolecenskeSatyCollectionsQuery = spolecenskeSatyCollections();
  const collectionsList =
    spolecenskeSatyCollectionsQuery.django.allCategories.edges[0].node
      .collectionSet.edges;

  return (
    <CategoryTemplatePage
      title="Společenské šaty"
      slug="spolecenske-saty"
      description="test"
      collectionsList={collectionsList}
      guide={true}
    />
  );
};

export default SpolecenskeSatyPage;
