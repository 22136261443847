import {graphql, useStaticQuery} from 'gatsby';

const spolecenskeSatyCollections = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = useStaticQuery(
        graphql`
            query {
                django {
                    allCategories(slug: "spolecenske-saty") {
                        edges {
                            node {
                                collectionSet(isListed: true) {
                                    edges {
                                        node {
                                            name
                                            id
                                            slug
                                            dressSet(first: 1) {
                                                edges {
                                                    node {
                                                        id
                                                        name
                                                        dresspictureSet(first: 1) {
                                                            edges {
                                                                node {
                                                                    processedPhoto
                                                                        processedPhotoSharp {
                                                                        childImageSharp {
                                                                            fluid {
                                                                            ...GatsbyImageSharpFluid
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    return data;
};

export default spolecenskeSatyCollections;